import React from "react"
import { Link } from "gatsby"
import logo from "./logo.svg"

import facebook from "./images/facebook.svg"
import twitter from "./images/twitter.svg"
import instagram from "./images/instagram.svg"
import linkedin from "./images/linkedin.svg"

import cx from "classnames"
import styles from "./style.module.scss"

const Footer = () => (
  <footer className={styles.footer}>
    <div className="container-large">
      <div className={styles.footerLinksHolder}>
        <ul className={styles.footerLinks}>
          <li><h4><b>Links</b></h4></li>
          <li><Link to="/terms-and-conditions">T&amp;C's</Link></li>
          <li><Link to="/privacy-policy">Privacy Policy</Link></li>
          <li><a href="https://react.sorted.com/">SortedREACT.com</a></li>
          <li><a href="https://www.myparceldelivery.com/">myparceldelivery.com</a></li>
        </ul>
        <ul className={styles.footerLinks}>
          <li><h4><b>Products</b></h4></li>
          <li><Link to="/products/pro">Sorted.PRO</Link></li>
          <li><a href="http://react.sorted.com">Sorted.React</a></li>
          <li><Link to="/products/hero">Sorted.Hero</Link></li>
        </ul>
        <ul className={styles.footerLinks}>
          <li><h4><b>Company</b></h4></li>
          <li><Link to="/about-us">About</Link></li>
          <li><Link to="/about-us/join-us">Careers</Link></li>
          <li><a href="https://blog.sorted.com/">Blog</a></li>
          <li><Link to="/contact">Contact us</Link></li>
        </ul>
        <div className={cx(styles.footerLinks, styles.footerLinkSocial)}>
          <h4>Get in touch</h4>
          <div className={styles.footerSocial}>
            <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/SortedOfficial/">
              <img alt="facebook logo" className={styles.footerSocialIcon} src={facebook}/>
            </a>
            <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/SortedOfficial">
              <img alt="twitter logo" className={styles.footerSocialIcon} src={twitter}/>
            </a>
            <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/Sorted_official/">
              <img alt="instagram logo" className={styles.footerSocialIcon} src={instagram}/>
            </a>
            <a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/company/sorted-official">
              <img alt="linkedin logo" className={styles.footerSocialIcon} src={linkedin}/>
            </a>
          </div>
        </div>
      </div>
      <div className={styles.imagefooterCopyright}>
        <Link to="/"><img src={logo} alt="Sorted logo" className={styles.logo} /></Link>
        <p>&copy; Sorted Group ltd.</p>
      </div>
    </div>
  </footer>
)

export default Footer
