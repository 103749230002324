import React from "react"
import PropTypes from "prop-types"

import Header from "./Header"
import Footer from "./Footer"
import JobFooter from "./JobFooter"

import "../sass/master.scss"

const Layout = ({
  children,
  JobSection,
  JobSectionLight
}) => (
  <>
    <Header />
    <div>
      <main>{children}</main>
    </div>
    {JobSection && <JobFooter />}
    {JobSectionLight && <JobFooter light />}
    <Footer />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
