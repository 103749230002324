import React from "react"
import { Link } from "gatsby";
import Button from "../Button"

import leftwhiteImg from "./images/leftwhite.svg"
import handIcons4 from "./images/handicons4white.svg"
import handIcons5 from "./images/handicons5white.svg"
import handIcons6 from "./images/handicons6white.svg"

import leftpinkImg from "./images/leftpink.svg"
import handIcons4pink from "./images/handicons4pink.svg"
import handIcons5pink from "./images/handicons5pink.svg"
import handIcons6pink from "./images/handicons6pink.svg"

import cx from "classnames"
import styles from "./styles.module.scss"

const JobFooter = ({ light }) => (
  <div className={cx(styles.jobFooter, light && styles.jobFooterLight)}>
    <div class="container-large">
      <div className={styles.jobFooterMobile}>
        <h3>Reckon you're<br />the sorted sort?</h3>
        <p className={light && styles.lightCopy}>We’re getting bigger, and learning as we grow. If you like the idea of working with a company on the verge of world domination, get in touch. We’re recruiting across all of our teams, and we’d love to hear from you.</p>
        {light && <Button theme="pink"><Link to="/about-us/join-us/jobs">View our roles</Link></Button>}
        {!light && <Button theme="whitePink"><Link to="/about-us/join-us/jobs">View our roles</Link></Button>}
      </div>
      <div className={styles.jobFooterDesktop}>
        <div>
          <h3>Are you the Sorted sort?<br/>We’d love to hear from you.</h3>
          <p className={light && styles.lightCopy}>We’re hiring smart, brave, agile and precise people across all disciplines, departments and experience levels.</p>
          <div className={styles.jobFooterDesktopButton}>
            {light && <Button theme="pink"><Link to="/about-us/join-us/jobs">View our roles</Link></Button>}
            {!light && <Button theme="whitePink"><Link to="/about-us/join-us/jobs">View our roles</Link></Button>}
            <img src={light ? leftpinkImg : leftwhiteImg} alt="hand pointing left" />
          </div>
        </div>
        <div classNames={styles.jobFooterDesktopHands}>
          <img alt="hand of good icons" src={light ? handIcons4pink : handIcons4} className={styles.handIcon4} />
          <img alt="hand of good icons" src={light ? handIcons5pink : handIcons5} className={styles.handIcon5} />
          <img alt="hand of good icons" src={light ? handIcons6pink : handIcons6} className={styles.handIcon6} />
        </div>
      </div>
    </div>
  </div>
)

export default JobFooter