import React from "react"

import cx from "classnames"
import styles from "./styles.module.scss"

const Button = ({
  children,
  className,
  theme,
  ...props
}) => (
  <button className={cx(styles.button, styles[theme], className && className)}
    {...props}>{ children }
  </button>
);

export default Button
