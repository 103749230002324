import React from "react"
import { Link } from "gatsby"
import cx from "classnames"
import styles from "./styles.module.scss"

const Navigation = ({ active, toggleNav }) => (
  <>
    {active && <div className={styles.bgOverlay} />}
    <nav className={cx(styles.nav, active && styles.navActive)}>
      <div
        className={styles.closeIcon}
        onClick={() => toggleNav()}
      />
      <div className={styles.mobileSplit}>
        <ol className={styles.linkBlockContainerMobile}>
          <li><h4>Products</h4></li>
          <li>
            <Link to="/products/hero">
              <div className={cx(styles.linkBlock, styles.linkBlockPink)}>
                <div className={styles.linkBlockIconHolder}>
                  <div className={styles.linkBlockIcon} />
                </div>
                <div className={styles.linkBlockCopy}>
                  <h4 className={styles.link}>Delivery options</h4>
                  <p className={styles.copy}>Sorted.HERO Lorem ipsum dolor sit amet</p>
                </div>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/products/pro">
              <div className={cx(styles.linkBlock, styles.linkBlockPink)}>
                <div className={styles.linkBlockIconHolder}>
                  <div className={styles.linkBlockIcon} />
                </div>
                <div className={styles.linkBlockCopy}>
                  <h4 className={styles.link}>Modern world warehouse</h4>
                  <p className={styles.copy}>Sorted.PRO Lorem ipsum dolor sit amet, concser</p>
                </div>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/products/react">
              <div className={cx(styles.linkBlock, styles.linkBlockPink)}>
                <div className={styles.linkBlockIconHolder}>
                  <div className={styles.linkBlockIcon} />
                </div>
                <div className={styles.linkBlockCopy}>
                  <h4 className={styles.link}>Powerful delivery tracking</h4>
                  <p className={styles.copy}>Sorted.REACT Lorem ipsum dolor sit amet</p>
                </div>
              </div>
            </Link>
          </li>
        </ol>
        <div className="bg-purple80">
          <ol className={styles.linkBlockContainerMobile}>
            <li><h4 className={cx(styles.mobileStandardLink, styles.bumpedh4)}><Link to="/carriers">Carriers</Link></h4></li>
            <li><h4 className={styles.mobileStandardLink}><Link to="/discover">Discover</Link></h4></li>
            <li><h4 className={styles.mobileStandardLink}><Link to="/about-us">About us</Link></h4></li>
            <li><h4 className={styles.mobileStandardLink}><a href="https://blog.sorted.com/">Blog</a></h4></li>
            <li><h4 className={styles.mobileStandardLink}><Link to="/contact">Contact</Link></h4></li>
          </ol>
        </div>
      </div>
      <a className={styles.mobileCTA} href="https://www.google.com">Request a product demo</a>
    </nav>
    <div className={cx(styles.navDesktop)}>
      <ul>
        <li>
          Products
          <ul style={{ width: "430px" }} className={styles.linkBlockContainer}>
            <div className={styles.cutInTriangle} />
            <li>
              <Link to="/products/hero">
                <div className={cx(styles.linkBlock, styles.linkBlockPink)}>
                  <div className={styles.linkBlockIconHolder}>
                    <div className={styles.linkBlockIcon} />
                  </div>
                  <div className={styles.linkBlockCopy}>
                    <h4 className={styles.link}>Delivery options</h4>
                    <p className={styles.copy}>Sorted.HERO Lorem ipsum dolor sit amet</p>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/products/pro">
                <div className={cx(styles.linkBlock, styles.linkBlockPink)}>
                  <div className={styles.linkBlockIconHolder}>
                    <div className={styles.linkBlockIcon} />
                  </div>
                  <div className={styles.linkBlockCopy}>
                    <h4 className={styles.link}>Modern world warehouse</h4>
                    <p className={styles.copy}>Sorted.PRO Lorem ipsum dolor sit amet, concser</p>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/products/react">
                <div className={cx(styles.linkBlock, styles.linkBlockPink)}>
                  <div className={styles.linkBlockIconHolder}>
                    <div className={styles.linkBlockIcon} />
                  </div>
                  <div className={styles.linkBlockCopy}>
                    <h4 className={styles.link}>Powerful delivery tracking</h4>
                    <p className={styles.copy}>Sorted.REACT Lorem ipsum dolor sit amet</p>
                  </div>
                </div>
              </Link>
            </li>
          </ul>
        </li>
        <li><Link to="/discover">Discover</Link></li>
        <li><Link to="/carriers">Carriers</Link></li>
        <li>
          <Link to="/about-us">About us</Link>
          <ul style={{ width: "215px" }} className={styles.linkBlockContainer}>
            <div className={styles.cutInTriangle} />
            <li>
              <Link to="/about-us/culture">
                <div className={cx(styles.linkBlock, styles.linkBlockGrey)}>
                  <div className={styles.linkBlockIconHolder}>
                    <div className={styles.linkBlockIcon} />
                  </div>
                  <div className={styles.linkBlockCopy}>
                    <h4 className={styles.link}>Culture</h4>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/about-us/our-people">
                <div className={cx(styles.linkBlock, styles.linkBlockGrey)}>
                  <div className={styles.linkBlockIconHolder}>
                    <div className={styles.linkBlockIcon} />
                  </div>
                  <div className={styles.linkBlockCopy}>
                    <h4 className={styles.link}>Our people</h4>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/about-us/sorted-house">
                <div className={cx(styles.linkBlock, styles.linkBlockGrey)}>
                  <div className={styles.linkBlockIconHolder}>
                    <div className={styles.linkBlockIcon} />
                  </div>
                  <div className={styles.linkBlockCopy}>
                    <h4 className={styles.link}>Sorted house</h4>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/about-us/join-us">
                <div className={cx(styles.linkBlock, styles.linkBlockGrey)}>
                  <div className={styles.linkBlockIconHolder}>
                    <div className={styles.linkBlockIcon} />
                  </div>
                  <div className={styles.linkBlockCopy}>
                    <h4 className={styles.link}>Join us</h4>
                  </div>
                </div>
              </Link>
            </li>
          </ul>
        </li>
        <li><a href="https://blog.sorted.com/">Blog</a></li>
        <li><Link to="/contact">Contact</Link></li>
        <li>
          Docs
          <ul  style={{ width: "400px" }} className={styles.linkBlockContainer}>
            <div className={styles.cutInTriangle} />
            <li>
              <Link to="/about-us/culture">
              <div className={cx(styles.linkBlock, styles.linkBlockPink)}>
                  <div className={styles.linkBlockIconHolder}>
                    <div className={styles.linkBlockIcon} />
                  </div>
                  <div className={styles.linkBlockCopy}>
                    <h4 className={styles.link}>Sorted.PRO</h4>
                    <p className={styles.copy}>Sorted.PRO Lorem ipsum dolor sit amet</p>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/about-us/our-people">
                <div className={cx(styles.linkBlock, styles.linkBlockPink)}>
                  <div className={styles.linkBlockIconHolder}>
                    <div className={styles.linkBlockIcon} />
                  </div>
                  <div className={styles.linkBlockCopy}>
                    <h4 className={styles.link}>Sorted.REACT</h4>
                    <p className={styles.copy}>Sorted.REACT Lorem ipsum dolor sit amet</p>
                  </div>
                </div>
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </>
)

export default Navigation
