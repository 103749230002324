import React, { Component } from "react"
import { Link } from "gatsby"
import Button from "../Button"
import Navigation from "../Navigation"
import logo from "./logo.svg"

import cx from "classnames"
import styles from "./styles.module.scss"

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navActive: false,
      scrollStatus: false
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", () => {
      window.pageYOffset === 0
        ? this.setState({ scrollStatus: false }) : this.setState({ scrollStatus: true });
    });
  }

  render () {
    const { navActive, scrollStatus } = this.state;
    return (
      <div className={cx(styles.stickyHeader, scrollStatus && styles.scrolledHeader)}>
        <header className={styles.header}>
          <div className={cx('container-large', styles.headerInner)}>
            <Link to="/">
              <img src={logo} className={styles.logo} alt="sorted logo" />
            </Link>
            <div>
              <Button className={styles.demoButton} theme="whiteoutline"><Link to="/request-a-demo">Request a demo</Link></Button>
              <div className={styles.navIcon} onClick={() => this.setState(prevState => ({ navActive: !prevState.navActive }))}>
                <span />
                <span />
                <span />
              </div>
            </div>
          </div>
        </header>
        <div className="container-large">
          <Navigation
            active={navActive}
            toggleNav={() => this.setState(prevState => ({ navActive: !prevState.navActive }))}
          />
        </div>
      </div>
    )
  }
}

export default Header
